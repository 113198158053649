import React, { useState, useEffect } from 'react';
import { Modal, Form, Tab, Tabs, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';



// Popup modal for entering additional participant emails.
// extraCount: number of email fields to display.
function ParticipantsModal({ show, onHide, participantEmails, setParticipantEmails, extraCount }) {
  const handleChange = (index, value) => {
    const newEmails = [...participantEmails];
    newEmails[index] = value;
    setParticipantEmails(newEmails);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Enter Participant Emails</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Array.from({ length: extraCount }, (_, index) => (
          <Form.Group key={index} controlId={`participantEmail-${index}`} className="mt-2">
            <Form.Label>Additional Participant {index + 1} Email</Form.Label>
            <Form.Control 
              type="email" 
              value={participantEmails[index] || ''} 
              onChange={(e) => handleChange(index, e.target.value)} 
              placeholder="Enter email address" 
            />
          </Form.Group>
        ))}
      
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={onHide}>
          Save Emails
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function CreateWalletModal({ show, handleClose, onWalletCreated, loggedInEmail }) {
  // Manage active tab.
  const [activeTab, setActiveTab] = useState('basic');
  // Basic tab fields.
  const [walletName, setWalletName] = useState('');
  const [coSigner, setCoSigner] = useState('');
  // New fields for one-time purchase option.
  const [oneTimePurchase, setOneTimePurchase] = useState(false);
  const [transactionRole, setTransactionRole] = useState(''); // 'purchaser' or 'seller'
  const [purchaseInfo, setPurchaseInfo] = useState('');
  // Advanced tab fields.
  const [advancedWalletName, setAdvancedWalletName] = useState('');
  const [keyManagementOption, setKeyManagementOption] = useState('server'); // 'server' or 'external'
  // Dropdowns.
  const [totalParticipants, setTotalParticipants] = useState('');
  const [requiredSignatures, setRequiredSignatures] = useState('');
  // State for additional participant emails from the popup.
  const [participantEmails, setParticipantEmails] = useState([]);
  const [showParticipantsPopup, setShowParticipantsPopup] = useState(false);
  // Risk warning modal state.
  const [showRiskWarningModal, setShowRiskWarningModal] = useState(false);
  // Confirmation modal state.
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmationSummary, setConfirmationSummary] = useState(null);
  const [confirmData, setConfirmData] = useState(null);
  // Transaction advice state.
  // We store advice as an array.
  const [adviceText, setAdviceText] = useState([]);
  // For one-time transactions, require acknowledgment of the transaction advice.
  const [hasAcknowledgedAdvice, setHasAcknowledgedAdvice] = useState(false);
  const [walletType, setWalletType] = useState('bitcoin');
  

  // Compute number of extra participant email fields:
  const extraParticipantsCount =
    totalParticipants === ''
      ? 0
      : keyManagementOption === 'server'
      ? parseInt(totalParticipants, 10) - 2
      : parseInt(totalParticipants, 10) - 1;

  // Update participantEmails length when totalParticipants or keyManagementOption changes.
  useEffect(() => {
    if (totalParticipants === '') {
      setParticipantEmails([]);
    } else {
      setParticipantEmails(prev => {
        const newLength = extraParticipantsCount;
        const newEmails = [...prev];
        if (newEmails.length < newLength) {
          for (let i = newEmails.length; i < newLength; i++) {
            newEmails.push('');
          }
        } else if (newEmails.length > newLength) {
          newEmails.length = newLength;
        }
        return newEmails;
      });
    }
  }, [totalParticipants, keyManagementOption, extraParticipantsCount]);

  // Generate options for Total Wallet Participants.
  const getTotalParticipantsOptions = () => {
    const minTotal = keyManagementOption === 'server' ? 3 : 2;
    const options = [];
    for (let i = minTotal; i <= 10; i++) {
      options.push(i);
    }
    return options;
  };

  // Generate options for Required Signatures.
  const getRequiredSignaturesOptions = () => {
    const options = [];
    if (totalParticipants === '') return options;
    const tot = parseInt(totalParticipants, 10);
    const max = keyManagementOption === 'server' ? tot - 1 : tot;
    for (let i = 2; i <= max; i++) {
      options.push(i);
    }
    return options;
  };

  // Function to fetch transaction advice from an AI service.
  const fetchAdvice = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/get-transaction-advice`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ role: transactionRole, reason: purchaseInfo }),
      });
      const data = await response.json();
      // Ensure that data.advice is an array.
      setAdviceText(Array.isArray(data.advice) ? data.advice : []);
    } catch (error) {
      console.error("Error fetching transaction advice:", error);
      setAdviceText([
        "We could not retrieve recommendations at this time. Please ensure you have appropriate documentation for your transaction."
      ]);
    }
  };

  // When the confirmation modal is shown for a one-time transaction, fetch the advice.
  useEffect(() => {
    if (showConfirmModal && oneTimePurchase && adviceText.length === 0) {
      fetchAdvice();
    }
  }, [showConfirmModal, oneTimePurchase, adviceText]);

  // Build payload and confirmation summary.
  const buildPayloadAndSummary = () => {
    let finalWalletName = '';
    let participantsArray = [];
    let payload = {};
    let summary = null;

    if (activeTab === 'basic') {
      finalWalletName = walletName.trim();
      if (!finalWalletName) {
        alert("Please enter a wallet name.");
        return null;
      }

      if (oneTimePurchase) {
        // Validate one-time transaction fields.
        if (!transactionRole) {
          alert("Please select if you are the purchaser or seller for this one-time transaction.");
          return null;
        }
        if (!coSigner || coSigner.trim() === '') {
          alert("Please provide the other party's email for the one-time transaction.");
          return null;
        }
        if (!purchaseInfo || purchaseInfo.trim() === '') {
          alert("Please provide service/purchase details.");
          return null;
        }
        // For one-time transactions, include exactly two participants.
        participantsArray = [
          { email: loggedInEmail },
          { email: coSigner.trim() }
        ];
        payload = {
          walletName: finalWalletName,
          oneTimePurchase: true,
          purchaseDetails: purchaseInfo.trim(),
          seller: transactionRole === 'seller' ? loggedInEmail : coSigner.trim(),
          purchaser: transactionRole === 'purchaser' ? loggedInEmail : coSigner.trim(),
          participants: participantsArray,
          walletType,

        };
        summary = (
          <>
            <div><strong>Wallet Name:</strong> {finalWalletName}</div>
            <div><strong>One-Time Transaction Wallet</strong></div>
            <div><strong>Seller:</strong> {payload.seller}</div>
            <div><strong>Purchaser:</strong> {payload.purchaser}</div>
            <div><strong>Service/Purchase Details:</strong> {payload.purchaseDetails}</div>
          </>
        );
      } else {
        // Regular wallet.
        if (!coSigner || coSigner.trim() === '') {
          alert('Please provide a co-signer email.');
          return null;
        }
        participantsArray = [
          { email: 'Server (holds one key)' },
          { email: loggedInEmail },
          { email: coSigner.trim() }
        ];
        payload = {
          walletName: finalWalletName,
          participants: participantsArray,
        };
        summary = (
          <>
            <div>
              <strong>Wallet Name:</strong> {finalWalletName}
            </div>
            <div>
              <strong>Configuration:</strong>
              <OverlayTrigger 
                placement="right" 
                overlay={
                  <Tooltip id="tooltip-basic">
                    This configuration uses a 2-of-3 multisig setup, meaning two approvals are required from the secure server key, your personal key, and your co-signer’s key.
                  </Tooltip>
                }
              >
                <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                  2 of 3 multisig wallet
                </span>
              </OverlayTrigger>
            </div>
            <div><strong>Wallet Type:</strong> {walletType.charAt(0).toUpperCase() + walletType.slice(1)}</div>

            <div>
              <strong>Participants:</strong>
            </div>
            <ul>
              {participantsArray.map((p, idx) => (
                <li key={idx}>{p.email}</li>
              ))}
            </ul>
          </>
        );
      }
    }
    return { payload, summary, participants: participantsArray };
  };

  // Risk warning logic.
  const shouldWarnRisk = (result) => {
    if (!result) return false;
    if (activeTab === 'basic') {
      return false;
    }
    if (activeTab === 'advanced' && parseInt(totalParticipants, 10) === parseInt(requiredSignatures, 10)) {
      return true;
    }
    return false;
  };

  // Submit wallet to backend.
  const submitWallet = async () => {
    if (!confirmData) return;
    try {
      const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/wallets`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(confirmData.payload),
      });
      if (!res.ok) throw new Error('Failed to create wallet');
      onWalletCreated && onWalletCreated();
      handleClose();
      // Reset fields.
      setWalletName('');
      setAdvancedWalletName('');
      setCoSigner('');
      setKeyManagementOption('server');
      setRequiredSignatures('');
      setTotalParticipants('');
      setParticipantEmails([]);
      setOneTimePurchase(false);
      setTransactionRole('');
      setPurchaseInfo('');
      setActiveTab('basic');
      setShowConfirmModal(false);
      setConfirmData(null);
      setConfirmationSummary(null);
      setShowRiskWarningModal(false);
      // Reset advice states.
      setAdviceText([]);
      setHasAcknowledgedAdvice(false);
    } catch (err) {
      console.error('Error creating wallet:', err);
      alert(err.message);
    }
  };

  // Handler for form submission.
  const handleSubmit = (e) => {
    e.preventDefault();
    const result = buildPayloadAndSummary();
    if (!result) return;
    if (shouldWarnRisk(result)) {
      setConfirmData(result);
      setConfirmationSummary(result.summary);
      setShowRiskWarningModal(true);
    } else {
      setConfirmData(result);
      setConfirmationSummary(result.summary);
      setShowConfirmModal(true);
    }
  };

  // When user confirms risk warning.
  const handleRiskWarningContinue = () => {
    setShowRiskWarningModal(false);
    setShowConfirmModal(true);
  };

  // When the user confirms wallet creation from the confirmation modal.
  // For one-time transactions, require acknowledgment of the transaction advice.
  const handleConfirmCreation = () => {
    if (oneTimePurchase && !hasAcknowledgedAdvice) {
      alert("Please review the transaction protection recommendations and acknowledge that you understand them.");
      return;
    }
    submitWallet();
  };

  // Handle closing the modal and resetting states
  const handleCloseModal = () => {
    // Reset the advice text when modal is closed
    setAdviceText([]);
    setHasAcknowledgedAdvice(false); // Reset the acknowledgment state
    // Reset other form states if needed
    setWalletName('');
    setCoSigner('');
    setOneTimePurchase(false);
    setTransactionRole('');
    setPurchaseInfo('');
    setActiveTab('basic');
    setShowConfirmModal(false);
    setConfirmData(null);
    setConfirmationSummary(null);
    setShowRiskWarningModal(false);
    setShowParticipantsPopup(false);
    handleClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Multisig Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} id="create-wallet-tabs">
              <Tab eventKey="basic" title="Basic">
                <Form.Group controlId="walletNameBasic" className="mt-3">
                  <Form.Label>Wallet Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={walletName}
                    onChange={(e) => setWalletName(e.target.value)}
                    placeholder="Enter wallet name"
                  />
                </Form.Group>
                <Form.Group controlId="walletType" className="mt-3">
  <Form.Label>Wallet Type</Form.Label>
  <Form.Select value={walletType} onChange={(e) => setWalletType(e.target.value)}>
    <option value="bitcoin">Bitcoin</option>
    <option value="ethereum" disabled>Ethereum (Coming Soon)</option>
    <option value="fiat-cash" disabled>Cash Deal (Coming Soon)</option>
    <option value="fiat-wire" disabled>Wire Transfer (Coming Soon)</option>
  </Form.Select>
</Form.Group>


                <Form.Group controlId="coSigner" className="mt-3">
                  <Form.Label>Co-Signer Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={coSigner}
                    onChange={(e) => setCoSigner(e.target.value)}
                    placeholder="Enter co-signer's email"
                  />
                </Form.Group>
                {/* Display configuration info for non-one-time transactions */}
                {!oneTimePurchase && walletName && coSigner && (
                  <div className="mt-3">
                    <strong>Configuration:</strong>
                    <OverlayTrigger 
                      placement="right" 
                      overlay={
                        <Tooltip id="tooltip-basic">
                          This configuration uses a 2-of-3 multisig setup, meaning two approvals are required from the secure server key, your personal key, and your co-signer’s key.
                        </Tooltip>
                      }
                    >
                      <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                        2 of 3 multisig wallet
                      </span>
                    </OverlayTrigger>
                  </div>
                )}
                {/* One-Time Purchase Option */}
                <Form.Group controlId="oneTimePurchase" className="mt-3">
                  <Form.Check
                    type="checkbox"
                    label="This wallet is intended for a one-time transaction purchase."
                    checked={oneTimePurchase}
                    onChange={(e) => setOneTimePurchase(e.target.checked)}
                  />
                </Form.Group>
                {oneTimePurchase && (
                  <>
                    <Form.Group controlId="transactionRole" className="mt-3">
                      <Form.Label>Role in Transaction</Form.Label>
                      <div>
                        <Form.Check
                          inline
                          label="Purchaser"
                          name="transactionRole"
                          type="radio"
                          id="purchaser"
                          checked={transactionRole === 'purchaser'}
                          onChange={() => setTransactionRole('purchaser')}
                        />
                        <Form.Check
                          inline
                          label="Seller"
                          name="transactionRole"
                          type="radio"
                          id="seller"
                          checked={transactionRole === 'seller'}
                          onChange={() => setTransactionRole('seller')}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group controlId="purchaseInfo" className="mt-3">
                      <Form.Label>Service/Purchase Details</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={purchaseInfo}
                        onChange={(e) => setPurchaseInfo(e.target.value)}
                        placeholder="Provide additional details about the service or purchase"
                      />
                    </Form.Group>
                  </>
                )}
              </Tab>
              <Tab eventKey="advanced" title="Advanced">
                <Form.Group controlId="advancedWalletName" className="mt-3">
                  <Form.Label>Wallet Name (Required)</Form.Label>
                  <Form.Control
                    type="text"
                    value={advancedWalletName}
                    onChange={(e) => setAdvancedWalletName(e.target.value)}
                    placeholder="Enter wallet name for advanced configuration"
                  />
                </Form.Group>
                <Form.Group controlId="keyManagement" className="mt-3">
                  <Form.Label>Key Management</Form.Label>
                  <div>
                    <Form.Check
                      inline
                      label="Server holds one key"
                      name="keyManagement"
                      type="radio"
                      id="server-key"
                      checked={keyManagementOption === 'server'}
                      onChange={() => {
                        setKeyManagementOption('server');
                        if (totalParticipants !== '' && parseInt(totalParticipants, 10) < 3) {
                          setTotalParticipants('3');
                        }
                        if (requiredSignatures !== '' && parseInt(requiredSignatures, 10) < 2) {
                          setRequiredSignatures('2');
                        }
                      }}
                    />
                    <Form.Check
                      inline
                      label="User selects all keys"
                      name="keyManagement"
                      type="radio"
                      id="external-key"
                      checked={keyManagementOption === 'external'}
                      onChange={() => setKeyManagementOption('external')}
                    />
                  </div>
                </Form.Group>
                <Form.Group controlId="totalParticipants" className="mt-3">
                  <Form.Label>Total Wallet Participants</Form.Label>
                  <Form.Control
                    as="select"
                    value={totalParticipants}
                    onChange={(e) => setTotalParticipants(e.target.value)}
                  >
                    <option value="">Select Total Wallet Participants</option>
                    {getTotalParticipantsOptions().map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="requiredSignatures" className="mt-3">
                  <Form.Label>Required Signatures</Form.Label>
                  <Form.Control
                    as="select"
                    value={requiredSignatures}
                    onChange={(e) => setRequiredSignatures(e.target.value)}
                    disabled={totalParticipants === ''}
                  >
                    <option value="">Select Required Signatures</option>
                    {getRequiredSignaturesOptions().map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <div className="mt-3">
                  <Button 
                    variant="secondary" 
                    onClick={() => setShowParticipantsPopup(true)}
                    disabled={totalParticipants === '' || requiredSignatures === ''}
                  >
                    Enter Participant Emails
                  </Button>
                </div>
              </Tab>
            </Tabs>
            <Button variant="primary" type="submit" className="mt-3">
              Create Wallet
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <ParticipantsModal 
        show={showParticipantsPopup} 
        onHide={() => setShowParticipantsPopup(false)} 
        participantEmails={participantEmails} 
        setParticipantEmails={setParticipantEmails}
        extraCount={extraParticipantsCount}
      />
      {/* Risk Warning Modal */}
      <Modal show={showRiskWarningModal} onHide={() => setShowRiskWarningModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Warning: All Keys Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activeTab === 'basic' ? (
            <div>
              You are about to create a 2-of-2 wallet. This configuration requires every private key to approve transactions.
              <br /><br />
              <strong>Important:</strong> While a server key can offer a secure backup when not every key is required, in a 2-of-2 setup every key is mandatory. Losing any key may result in permanent loss of funds.
              <br /><br />
              Consider leveraging our server key in a configuration that requires fewer than all keys for added protection.
            </div>
          ) : (
            <div>
              You are about to create a {requiredSignatures} of {totalParticipants} multisig wallet, meaning all {totalParticipants} keys are required.
              <br /><br />
              <strong>Risk Notice:</strong> When every key is required, the loss of any single private key can prevent transactions from being approved.
              <br /><br />
              <strong>Why Use a Server Key?</strong> Our secure server key is managed by professionals and acts as a reliable backup. For instance, if you selected a 3-of-3 configuration, you could instead configure a 3-of-4 multisig wallet (adding one extra server key) to reduce the risk of losing access.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRiskWarningModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleRiskWarningContinue}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Wallet Creation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confirmationSummary}
          {oneTimePurchase && (
            <div className="mt-3">
              <h5>Transaction Protection Recommendations</h5>
              {adviceText && adviceText.length > 0 ? (
                // Render transaction advice using ul/li with icons.
                <ul
                  className="list-disc ml-6"
                  style={{ listStyleType: 'disc', marginLeft: '1.5rem', paddingLeft: '1.5rem' }}
                >
                  {adviceText.map((item, index) => (
                    <li
                      key={index}
                      style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}
                    >
                      <div style={{ width: '22px', flexShrink: 0 }}>
  <FaCheckCircle style={{ color: 'green', fontSize: '18px' }} />
</div>
<div style={{ flex: 1 }}>{item}</div>

                    </li>
                  ))}
                </ul>
              ) : (
                <div className="text-center">
                  <Spinner animation="border" size="sm" />
                  <span className="ml-2">Fetching recommendations...</span>
                </div>
              )}
              <Form.Group controlId="acknowledgeAdvice" className="mt-2">
                <Form.Check
                  type="checkbox"
                  label="I acknowledge that I have reviewed and understand the recommended steps and evidence required to protect myself from potential fraud."
                  checked={hasAcknowledgedAdvice}
                  onChange={(e) => setHasAcknowledgedAdvice(e.target.checked)}
                />
              </Form.Group>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmCreation} disabled={oneTimePurchase && !hasAcknowledgedAdvice}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
