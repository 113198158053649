// src/PrivateRoute.js

import React from 'react';
import { useAuth } from './AuthContext';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export default function PrivateRoute() {
  const { authenticated, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    // Optionally render a loading spinner
    return <div>Loading...</div>;
  }

  // If not authenticated, redirect to home (or login).
  if (!authenticated) {
    return <Navigate to="/login" replace />;
  }

  // If the user is authenticated but is at the '/home' route,
  // automatically redirect them to the dashboard.
  if (location.pathname === '/home') {
    return <Navigate to="/multisig/dashboard" replace />;
  }

  // Otherwise, render the protected content.
  return <Outlet />;
}

