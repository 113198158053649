//Modals/SignupModal.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signupUser } from '../api';
import '../App.css';
import useAllowedCountries from '../hooks/useAllowedCountries';



const Signup = () => {
  // Inputs
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('USA');
  const [businessName, setBusinessName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userType, setUserType] = useState('individual');
  const [message, setMessage] = useState('');
  const [termsAgreed, setTermsAgreed] = useState(false);

  const navigate = useNavigate();

  // 1) Simple email regex:  "something@something.something"
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // 2) Check that everything is filled + email is valid + terms are agreed
  const isFormValid = () => {
    // Must match your required fields:
    if (!email.trim() || !emailRegex.test(email.trim())) return false;
    if (!password.trim()) return false;
    if (!address.trim()) return false;
    if (!city.trim()) return false;
    if (!state.trim()) return false;
    if (!phoneNumber.trim()) return false;

    // For individuals, firstName & lastName must not be empty
    if (userType === 'individual') {
      if (!firstName.trim() || !lastName.trim()) return false;
    }
    
    // For businesses, businessName must not be empty
    if (userType === 'business') {
      if (!businessName.trim()) return false;
    }
    
    // Must check the "Agree to Terms" box
    if (!termsAgreed) return false;

    return true; // All checks passed
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    setMessage('');

    // Double-check validity in case user bypassed UI
    if (!isFormValid()) {
      setMessage('Please fill out all fields in a valid format and agree to the terms.');
      return;
    }

    try {
      const responseData = await signupUser(
        email,
        password,
        firstName,
        lastName,
        address,
        city,
        state,
        country,
        businessName,
        phoneNumber,
        userType
      );
      setMessage('Signup successful!');
      console.log('Signup successful:', responseData);

      // Redirect to the /login page after a short delay
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      console.error('Signup error:', error);
      setMessage(`Error: ${error.message}`);
    }
  };

  const allowedCountries = useAllowedCountries();

  return (
    <div className="login-container">
      <div className="login-card">
      <h2 className="text-2xl font-bold mb-4 signup-heading">
      Create Your Tavri Account
      </h2>
        
        {/* User Type Toggle */}
        <div className="user-type-toggle flex justify-center space-x-4 mb-4">
          <label className="var(--text-color)">
            <input
              type="radio"
              value="individual"
              checked={userType === 'individual'}
              onChange={() => setUserType('individual')}
              className="mr-1"
            />
            Individual
          </label>
          <label className="var(--text-color)">
            <input
              type="radio"
              value="business"
              checked={userType === 'business'}
              onChange={() => setUserType('business')}
              className="mr-1"
            />
            Business
          </label>
        </div>

        <form onSubmit={handleSignup} className="flex flex-col space-y-4">
          {/* For individuals */}
          {userType === 'individual' && (
            <div className="flex space-x-4">
              <input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="form-input"
              />
              <input
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="form-input"
              />
            </div>
          )}

          {/* For businesses */}
          {userType === 'business' && (
            <input
              type="text"
              placeholder="Business Name"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              className="form-input"
            />
          )}

          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-input"
          />

          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-input"
          />

          <input
            type="text"
            placeholder="Enter your address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="form-input"
          />

          <input
            type="text"
            placeholder="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            className="form-input"
          />

          <input
            type="text"
            placeholder="State"
            value={state}
            onChange={(e) => setState(e.target.value)}
            className="form-input"
          />

<select
  value={country}
  onChange={(e) => setCountry(e.target.value)}
  className="form-select"
>
  <option value="">Select Country</option>
  {allowedCountries.map((c) => (
    <option key={c} value={c}>{c}</option>
  ))}
</select>


          <input
            type="text"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="form-input"
          />

          {/* Terms and Conditions */}
          <div className="terms-container flex items-center space-x-2">
            <input
              type="checkbox"
              id="agreeTerms"
              checked={termsAgreed}
              onChange={(e) => setTermsAgreed(e.target.checked)}
            />
            <label htmlFor="agreeTerms" className="var(--text-color)">
              I agree to the{' '}
              <a href="/terms" target="_blank" rel="noopener noreferrer" className="underline">
                terms and conditions
              </a>
            </label>
          </div>

          {/* Disable button if form isn't valid */}
          <button
            type="submit"
            disabled={!isFormValid()}
            className="mb-6 button"
          >
            Sign Up
          </button>
        </form>

        {message && <p className="message mt-4 var(--text-color)">{message}</p>}
      </div>
    </div>
  );
};

export default Signup;
