// src/pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import { Alert, Container, Button } from 'react-bootstrap';
import KycForm from '../Modals/KycForm';
import MediationModal from '../Modals/MediationModal';
import CreateWalletModal from '../Modals/CreateWalletModal';
import WalletList from '../components/WalletList';
import useWallets from '../hooks/useWallets';
import DisputeDetails from './DisputeDetails';
import { useAuth } from '../AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import AdviceModal from '../Modals/AdviceModal';
import './dashboard.css';
import '../Modals/AdviceModal.css';

const socket = io(process.env.REACT_APP_MAIN_SERVER_URL, {
  withCredentials: true,
  transports: ['websocket'],
});


export default function Dashboard({ navigateTo, onViewDispute, onCloseWallet, onCancelWallet }) {
  const { user } = useAuth();
  const loggedInEmail = user ? user.email : '';
  const nav = useNavigate();
  const location = useLocation();
  const [currentView, setCurrentView] = useState('dashboard');
  const [locallyAcknowledgedWallets, setLocallyAcknowledgedWallets] = useState([]);
  const {
    wallets, fetchWallets,
    activeWallets,
    closedWallets,
    disputedWallets,
    activeAndPendingWallets,
    totalBalance,
    pendingApprovalCount,
    recentTxCount,
  } = useWallets(loggedInEmail);

  const [reasonText, setReasonText] = useState('');

  const [showCreateWalletModal, setShowCreateWalletModal] = useState(false);
  const [showActiveWalletsInPlace, setShowActiveWalletsInPlace] = useState(false);
  const [showAdviceModal, setShowAdviceModal] = useState(false);
  const [pendingAdvice, setPendingAdvice] = useState([]);
  const [currentAdviceWalletId, setCurrentAdviceWalletId] = useState(null);

  useEffect(() => {
    if (!wallets.length || !loggedInEmail) return;
  
    const lowerEmail = loggedInEmail.toLowerCase();
  
    const targetWallet = wallets.find(wallet =>
      wallet.oneTimePurchase &&
      wallet.participants.some(p => p.email.toLowerCase() === lowerEmail) &&
      wallet.createdBy?.toLowerCase() !== lowerEmail && // exclude creator
      (!wallet.adviceAcknowledged || !wallet.adviceAcknowledged.includes(lowerEmail))
    );
  
    if (targetWallet) {
      const role = targetWallet.purchaser?.toLowerCase() === lowerEmail ? 'buyer' : 'seller';
      const advice = targetWallet.transactionAdvice?.[role] || [];
      const reason = targetWallet.rewrittenPurchaseDetails || targetWallet.purchaseDetails || '';
  
      setPendingAdvice(advice);
      setReasonText(reason); // ✅ use the setter here
      setCurrentAdviceWalletId(targetWallet._id);
      setShowAdviceModal(true);
    }
  }, [wallets, loggedInEmail]);
  
  
  
  

  async function acknowledgeAdvice(walletId) {
    try {
      const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/wallets/${walletId}/acknowledge-advice`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      });
  
      if (!res.ok) throw new Error('Failed to acknowledge advice');
      
      setShowAdviceModal(false);
      setCurrentAdviceWalletId(null);
      setPendingAdvice([]);
      await fetchWallets(); // Refresh state
    } catch (err) {
      console.error('Acknowledge advice error:', err);
      alert(err.message);
    }
  }
  
  

  const [closeNotifications, setCloseNotifications] = useState([]);
  const [acknowledgedClose, setAcknowledgedClose] = useState(() => {
    const stored = localStorage.getItem('acknowledgedClose');
    return stored ? JSON.parse(stored) : [];
  });
    

  const handleAcknowledgeClose = async (walletId) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/wallets/${walletId}/acknowledge-close`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });
      if (!res.ok) {
        const data = await res.json();
        throw new Error(data.error || 'Failed to acknowledge wallet closure');
      }
      const updated = [...acknowledgedClose, walletId];
      setAcknowledgedClose(updated);
      localStorage.setItem('acknowledgedClose', JSON.stringify(updated));
      setCloseNotifications((prev) => prev.filter((n) => n.walletId !== walletId));
    } catch (err) {
      console.error('Acknowledge wallet closure error:', err);
      alert(err.message);
    }
  };

  // ------------------------
  // Set view based on URL query parameter
  // ------------------------
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('view') === 'wallets') {
      setShowActiveWalletsInPlace(true);
    }
  }, [location]);

  // ------------------------
  // SOCKET EVENTS
  // ------------------------
  useEffect(() => {
    const handleWalletEvent = (data) => {
      if (data.event === 'walletClosed') {
        fetchWallets();
      }
    };
    socket.on('walletEvent', handleWalletEvent);
    return () => {
      socket.off('walletEvent', handleWalletEvent);
    };
  }, [fetchWallets]);

  // ------------------------
  // MODAL STATES (Dispute and KYC)
  // ------------------------
  const [showMediationModal, setShowMediationModal] = useState(false);
  const [showKycModal, setShowKycModal] = useState(false);

  // ------------------------
  // DISPUTE HANDLING
  // ------------------------
  // State to hold disputes fetched from the backend.
  const [disputes, setDisputes] = useState([]);
  // States for MediationModal usage:
  const [disputeWalletId, setDisputeWalletId] = useState('');
  const [disputeIdentityProvided, setDisputeIdentityProvided] = useState(false);

  async function handleDisputeInitiation(formData) {
    try {
      const res = await fetch(`${process.env.REACT_APP_MAIN_SERVER_URL}/disputes/initiate`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });
      if (!res.ok) {
        const data = await res.json();
        throw new Error(data.error || 'Failed to initiate dispute');
      }
      const result = await res.json();
      alert(result.message);
      await fetchWallets();
    } catch (err) {
      console.error('[Dashboard] Dispute initiation error:', err);
      alert('Failed to initiate dispute: ' + err.message);
    }
  }

  const handleKycClose = () => {
    setShowKycModal(false);
    setDisputeIdentityProvided(true);
  };

  // ------------------------
  // OTHER ACTIONS (Keys, Transactions, etc.)
  // ------------------------
  const [isGeneratingKeys, setIsGeneratingKeys] = useState(false);

  function handleGenerateKeys(walletId) {
    const baseUrl = process.env.REACT_APP_KEY_GEN_URL || '/key-generation';
    const popupUrl = `${baseUrl}/${walletId}/participant`;
  
    const popup = window.open(popupUrl, 'KeyGen', 'width=600,height=400,scrollbars=yes');
    if (!popup) {
      alert('Popup blocked or failed to open');
      setIsGeneratingKeys(false);
      return;
    }
  
    const handleMessage = (event) => {
      if (event.origin !== process.env.REACT_APP_KEY_GEN_URL.replace('/key-generation', '')) return;
      if (event.data && event.data.type === 'KEYGEN_COMPLETE') {
        alert('Key generation completed for wallet: ' + walletId);
        window.removeEventListener('message', handleMessage);
        if (!popup.closed) popup.close();
        setIsGeneratingKeys(false);
        fetchWallets();
      }
    };
  
    window.addEventListener('message', handleMessage);
    const checkClosed = setInterval(() => {
      if (popup.closed) {
        clearInterval(checkClosed);
        window.removeEventListener('message', handleMessage);
        setIsGeneratingKeys(false);
      }
    }, 500);
  }
  
  async function handleCancelTransaction(walletId, transactionId) {
    if (!window.confirm('Are you sure you want to cancel this transaction?')) return;
    try {
      const res = await fetch(`${process.env.REACT_APP_MAIN_SERVER_URL}/wallets/${walletId}/transaction/${transactionId}/cancel`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });
      if (!res.ok) {
        const data = await res.json();
        throw new Error(data.error || 'Failed to cancel transaction');
      }
      await fetchWallets();
    } catch (err) {
      console.error('[Dashboard] Cancel transaction error:', err);
      alert('Failed to cancel transaction: ' + err.message);
    }
  }

  const handleCloseWallet = (walletId) => {
    if (onCloseWallet) {
      onCloseWallet(walletId);
    } else {
      if (!window.confirm("Are you sure you want to close this wallet?")) return;
      fetch(`${process.env.REACT_APP_MAIN_API_URL}/wallets/${walletId}/close`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      })
        .then((res) => res.json())
        .then(() => {
          fetchWallets();
          alert("Wallet closed successfully.");
        })
        .catch((err) => {
          console.error("Close wallet error:", err);
          alert(err.message);
        });
    }
  };

  const handleCancelWallet = async (walletId) => {
    if (onCancelWallet) {
      onCancelWallet(walletId);
    } else {
      if (!window.confirm("Are you sure you want to cancel this wallet request?")) return;
      try {
        const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/wallets/${walletId}/cancel`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        });
        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.error || 'Failed to cancel wallet request');
        }
        await fetchWallets();
        alert("Wallet request cancelled successfully.");
      } catch (err) {
        console.error("Cancel wallet error:", err);
        alert(err.message);
      }
    }
  };

  async function handleRemoveTransaction(walletId, transactionId) {
    if (!window.confirm('Are you sure you want to permanently remove this transaction?')) return;
    try {
      const res = await fetch(`${process.env.REACT_APP_MAIN_SERVER_URL}/wallets/${walletId}/transaction/${transactionId}/remove`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });
      if (!res.ok) {
        const data = await res.json();
        throw new Error(data.error || 'Failed to remove transaction');
      }
      await fetchWallets();
    } catch (err) {
      console.error('[Dashboard] Remove transaction error:', err);
      alert('Failed to remove transaction: ' + err.message);
    }
  }

  function confirmApproveTransaction(walletId, transactionId) {
    const popupUrl = `/approval/${walletId}/${transactionId}`;
    window.open(popupUrl, 'ApprovalPopup', 'width=600,height=500');
  }

  function viewDisputeHandler(walletId) {
    if (navigateTo) {
      navigateTo('disputeDetails', walletId);
    } else if (onViewDispute) {
      onViewDispute(walletId);
    } else {
      // Navigate to the correct dispute details route without appending walletId
      nav('/multisig/disputes');
    }
  }
  

  // ------------------------
  // Fetch disputes from GET /disputes 
  // ------------------------
  useEffect(() => {
    const fetchDisputesData = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/disputes`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });
        if (!res.ok) throw new Error(await res.text());
        const data = await res.json();
        console.log("Fetched disputes:", data.disputes);
        setDisputes(data.disputes || []);
      } catch (err) {
        console.error('Error fetching disputes:', err);
      }
    };
    fetchDisputesData();
  }, []);

  // ------------------------
  // Sort active and pending wallets: pending wallets first.
  // ------------------------
  const sortedActiveAndPendingWallets = activeAndPendingWallets.slice().sort((a, b) => {
    if (a.status === 'pending' && b.status !== 'pending') return -1;
    if (a.status !== 'pending' && b.status === 'pending') return 1;
    return 0;
  });

  // ------------------------
  // Recent Activity calculations
  // ------------------------
  const allTransactions = wallets.flatMap((w) =>
    (w.transactions || []).map((tx) => ({ ...tx, walletName: w.walletName }))
  );
  const sortedTxs = allTransactions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const recentActivity = sortedTxs.slice(0, 5).map((tx) => {
    let variant = 'pending';
    if (tx.status === 'approved') variant = 'received';
    if (tx.status === 'cancelled' || tx.status === 'cancelledAfterApproval') variant = 'sent';
    return {
      id: tx._id,
      type: tx.status === 'approved' ? 'Sent Payment' : tx.status === 'pending' ? 'Signature Request' : 'Cancelled',
      address: tx.toAddress || tx.walletName,
      amount: tx.amount ? `${tx.amount > 0 ? '+' : ''}${tx.amount} BTC` : 'N/A',
      time: new Date(tx.createdAt).toLocaleString(),
      variant,
    };
  });

  // If currentView is 'disputeDetails', render the DisputeDetails component inline.
  if (currentView === 'disputeDetails') {
    return (
      <Container className="my-4">
        <Button variant="secondary" onClick={() => setCurrentView('dashboard')}>
          Back to Dashboard
        </Button>
        <DisputeDetails />
      </Container>
    );
  }

  return (
    <div className="dashboard-container">
      {closeNotifications.map((notif) => (
        <Alert key={notif.walletId} variant="warning" dismissible={false} className="dark-alert">
          {notif.message}
          <button className="button" style={{ marginLeft: '1rem' }} onClick={() => handleAcknowledgeClose(notif.walletId)}>
            Acknowledge
          </button>
        </Alert>
      ))}

<AdviceModal
  show={showAdviceModal}
  advice={pendingAdvice}
  reason={reasonText}
  onAcknowledge={async () => {
    const wallet = wallets.find(w =>
      w.oneTimePurchase &&
      w.participants.some(p => p.email.toLowerCase() === loggedInEmail.toLowerCase()) &&
      (!w.adviceAcknowledged || !w.adviceAcknowledged.includes(loggedInEmail.toLowerCase()))
    );
    if (wallet) {
      try {
        await acknowledgeAdvice(wallet._id);
        setShowAdviceModal(false);
        setPendingAdvice([]);
        setReasonText('');
        await fetchWallets();
      } catch (err) {
        console.error(err);
      }
    }
  }}
/>




      {/* Stats Row */}
      <div className="stats-row">
        <div className="stats-card clickable" onClick={() => nav('/multisig/financial-overview')}>
          <h4>Total Balance</h4>
          <p className="stats-value">B {totalBalance.toFixed(4)}</p>
          <span className="stats-subtext">+2.5% from last month (example)</span>
        </div>
        <div className="stats-card clickable" onClick={() => setShowActiveWalletsInPlace(true)}>
          <h4>Active Wallets</h4>
          <p className="stats-value">{activeWallets.length}</p>
          <span className="stats-subtext">{pendingApprovalCount} pending approval</span>
        </div>
        <div className="stats-card clickable" onClick={() => nav('/multisig/transactions')}>
          <h4>Recent Transactions</h4>
          <p className="stats-value">{recentTxCount}</p>
          <span className="stats-subtext">Last 7 days</span>
        </div>
        <div
          className="stats-card clickable"
          onClick={() => {
            if (disputes.length > 0) {
              setCurrentView('disputeDetails');
            } else {
              alert("No disputes available for your wallet.");
            }
          }}
        >
          <h4>Disputes</h4>
          <p className="stats-value">{disputes.length}</p>
          <span className="stats-subtext">Current & Past Wallet Disputes</span>
        </div>
      </div>

      {/* Toggle between Recent Activity and Active & Pending Wallets */}
      <div className="activity-section">
        {showActiveWalletsInPlace ? (
          <>
            <div className="activity-header">
              <h5>Active & Pending Wallets</h5>
              <a
                href="#showActivity"
                onClick={(e) => {
                  e.preventDefault();
                  setShowActiveWalletsInPlace(false);
                }}
              >
                Back to Recent Activity
              </a>
            </div>
            <WalletList 
              wallets={sortedActiveAndPendingWallets}
              loggedInEmail={loggedInEmail}
              isGeneratingKeys={isGeneratingKeys}
              listType="active"
              showStatus={true}
              onCloseWallet={handleCloseWallet}
              onDisputeWallet={(walletId) => {
                setDisputeWalletId(walletId);
                setShowMediationModal(true);
              }}
              
              onGenerateKeys={handleGenerateKeys}
              onCancelWallet={handleCancelWallet}
              onCancelTransaction={handleCancelTransaction}
              onRemoveTransaction={handleRemoveTransaction}
              onApproveTransaction={confirmApproveTransaction}
              onViewDispute={viewDisputeHandler}
            />
          </>
        ) : (
          <>
            <div className="activity-header">
              <h5>Recent Activity</h5>
              <a href="#viewAll" onClick={(e) => e.preventDefault()}>
                View All
              </a>
            </div>
            <div className="activity-list">
              {recentActivity.map((activity) => (
                <div key={activity.id} className={`activity-item ${activity.variant}`}>
                  <div className="activity-info">
                    <div className="activity-type">{activity.type}</div>
                    <div className="activity-address">
                      {activity.type === 'Signature Request'
                        ? `Wallet: ${activity.address}`
                        : `From/To: ${activity.address}`}
                    </div>
                  </div>
                  <div className="activity-meta">
                    <div className="activity-amount">{activity.amount}</div>
                    <div className="activity-time">{activity.time}</div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {/* Create Wallet Button */}
      <div style={{ marginTop: '20px' }}>
        <button className="button" onClick={() => setShowCreateWalletModal(true)}>
          Create New Wallet
        </button>
      </div>

      {/* Create Wallet Modal */}
      <CreateWalletModal
        show={showCreateWalletModal}
        handleClose={() => setShowCreateWalletModal(false)}
        onWalletCreated={fetchWallets}
        loggedInEmail={loggedInEmail}
      />

      {/* Dispute Modal */}
      <MediationModal
        show={showMediationModal}
        onHide={() => setShowMediationModal(false)}
        walletId={disputeWalletId}
        disputeIdentityProvided={disputeIdentityProvided}
        setDisputeIdentityProvided={setDisputeIdentityProvided}
        onInitiateDispute={handleDisputeInitiation}
        setShowKycModal={setShowKycModal}
      />

      {/* KYC Modal */}
      <KycForm show={showKycModal} handleClose={handleKycClose} />
    </div>
  );
}
