// src/Modals/BTCDisputePaymentModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';



export default function BTCDisputePaymentModal({ show, onClose, btcAddress }) {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Pay with Bitcoin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please send exactly <strong>0.0008 BTC</strong> (~$50) to the address below:</p>
        <pre className="btc-address-box">{btcAddress}</pre>
        <p>Once sent, contact support or wait for 1 confirmation.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
