import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';

// Import your components
import Login from './pages/Login';
import Signup from './Modals/SignupModal';
import UpdateUserInfo from './UpdateUserInfo';
import Dashboard from './pages/Dashboard';
import BitcoinMultisigApp from './BitcoinMultisigApp';
import EmployeeInterface from './pages/EmployeeInterface';
import KeyGenPopup from './Modals/KeyGenPopup';
import ApprovalPopup from './Modals/ApprovalPopup';
import DisputeDetails from './pages/DisputeDetails';
import NotFound from './pages/NotFound';
import EmailPage from './pages/EmailPage';



const AuthProviderWithNavigation = ({ children }) => {
  return (
    <AuthProvider
      onSessionExpired={() => {
        // Handle session expiry (e.g., redirect to login)
      }}
    >
      {children}
    </AuthProvider>
  );
};

function App() {
  return (
    <AuthProviderWithNavigation>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        {/* Protected routes */}
        <Route element={<PrivateRoute />}>
          <Route path="/multisig/*" element={<BitcoinMultisigApp />} />
          <Route path="/UpdateUserInfo" element={<UpdateUserInfo />} />
          <Route path="/multisig/disputes" element={<DisputeDetails />} />
          <Route path="/approval/:walletId/:transactionId" element={<ApprovalPopup />} />
          <Route path="/key-generation/:walletId/participant" element={<KeyGenPopup />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/employee/*" element={<EmployeeInterface />} />
          <Route path="/employee/email" element={<EmailPage />} />

        </Route>

        {/* Catch-all 404 page */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthProviderWithNavigation>
  );
}

export default App;
