// src/Modals/DisputeSuccessModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export default function DisputeSuccessModal({ show, onClose, onConfirm }) {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Payment Confirmed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Your $50 payment has been received. Would you like to proceed with initiating the dispute?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>Cancel</Button>
        <Button className="button" onClick={onConfirm}>Initiate Dispute</Button>
      </Modal.Footer>
    </Modal>
  );
}
