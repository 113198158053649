// Modals/AdviceModal.js
import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import './AdviceModal.css'; // Import the animation styles

function AdviceModal({ show, advice, reason, onAcknowledge }) {
  const [isAcknowledging, setIsAcknowledging] = useState(false);

  const handleAcknowledge = async () => {
    setIsAcknowledging(true);
    await onAcknowledge();
    setTimeout(() => {
      setIsAcknowledging(false);
    }, 1000);
  };

  return (
    <Modal show={show} onHide={() => {}} backdrop="static" keyboard={false} className="advice-modal">
      <Modal.Header>
        <Modal.Title>Transaction Safety Advice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
  <div className="mb-3">
    <p>
      <strong>A new wallet has been created for:</strong><br />
      {reason}
    </p>
    <p className="text-muted" style={{ fontSize: '0.95rem' }}>
      Please review the transaction advice below. This guidance is tailored to your role in the transaction to help ensure a safe and successful exchange.
    </p>
  </div>

  {advice.length === 0 ? (
    <div className="text-center">
      <Spinner animation="border" />
      <p className="mt-2">Fetching advice...</p>
    </div>
  ) : (
    <ul style={{ listStyleType: 'disc', marginLeft: '1.5rem' }}>
      {advice.map((item, index) => (
        <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
          <div style={{ width: '22px', flexShrink: 0 }}>
            <FaCheckCircle style={{ color: 'green', fontSize: '18px' }} />
          </div>
          <div style={{ flex: 1 }}>{item}</div>
        </li>
      ))}
    </ul>
  )}

  <hr />
  <p style={{ fontSize: '0.95rem' }}>
    After acknowledging this advice, you’ll proceed to the next step: <strong>generating your secure cryptographic keys</strong> for this wallet.
  </p>
</Modal.Body>


      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleAcknowledge}
          disabled={advice.length === 0 || isAcknowledging}
          className={isAcknowledging ? 'acknowledge-animating' : ''}
        >
          {isAcknowledging ? 'Acknowledging...' : 'I Understand'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}


export default AdviceModal;
