// src/Modals/DisputePaymentModal.js

import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button } from 'react-bootstrap';
import BTCDisputePaymentModal from './BTCDisputePaymentModal'; // path based on your structure



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);



function PaymentForm({ walletId, onSuccess, onClose }) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setLoading(true);
    setMessage('');

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {},
      redirect: 'if_required',
    });

    if (error) {
      setMessage(error.message);
    } else if (paymentIntent.status === 'succeeded') {
      onSuccess();
      onClose();
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        className="button mt-3"
        type="submit"
        disabled={!stripe || !elements || loading}
      >
        {loading ? <Spinner size="sm" animation="border" /> : 'Pay $50'}
      </button>
      {message && <p className="mt-2 text-danger">{message}</p>}
    </form>
  );
}

export default function StripeDisputePaymentModal({ show, onClose, walletId, onSuccess }) {
  const [clientSecret, setClientSecret] = useState(null);
  const [showBTCInstructions, setShowBTCInstructions] = useState(false);

  useEffect(() => {
    const fetchClientSecret = async () => {
      const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/create-payment-intent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ walletId }),
      });
      const { clientSecret } = await res.json();
      setClientSecret(clientSecret);
    };

    if (show) fetchClientSecret();
  }, [show, walletId]);

  const appearance = { theme: 'stripe' };
  const options = { clientSecret, appearance };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Pay Dispute Fee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {clientSecret ? (
          <Elements stripe={stripePromise} options={options}>
            <PaymentForm walletId={walletId} onSuccess={onSuccess} onClose={onClose} />
          </Elements>
        ) : (
          <Spinner animation="border" />
        )}
        <Button variant="outline-secondary" onClick={() => setShowBTCInstructions(true)}>
  Pay with Bitcoin
</Button>
<BTCDisputePaymentModal
  show={showBTCInstructions}
  onClose={() => setShowBTCInstructions(false)}
  btcAddress={process.env.REACT_APP_BUSINESS_BTC_ADDRESS}
/>

      </Modal.Body>
    </Modal>
  );
}
