import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import StripeDisputePaymentModal from './DisputePaymentModal';
import DisputeSuccessModal from './DisputeSuccessModal';
import '../pages/dashboard.css';

export default function DisputeModal({
  show,
  onHide,
  walletId,
  disputeIdentityProvided,
  setDisputeIdentityProvided,
  onInitiateDispute,
  setShowKycModal,
}) {
  const [disputeLevel, setDisputeLevel] = useState('random_mediators');
  const [disputeEvidence, setDisputeEvidence] = useState('');
  const [disputeFiles, setDisputeFiles] = useState(null);
  const [hasPaidDisputeFee, setHasPaidDisputeFee] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [pendingDisputeForm, setPendingDisputeForm] = useState(null);

  // Step 1: Trigger payment modal if "employees" selected
  useEffect(() => {
    const checkDisputePayment = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/dispute-fee-status/${walletId}`, {
          credentials: 'include',
        });
        const data = await res.json();
        if (data.hasPaid) {
          setHasPaidDisputeFee(true);
        } else {
        
        }
      } catch (err) {
        console.error('Error checking dispute fee status:', err);
      }
    };

    if (disputeLevel === 'employees') {
      checkDisputePayment();
    }
  }, [disputeLevel, walletId]);

  // Step 2: When user clicks "Initiate Dispute"
  const handleSubmit = async () => {
    if (!disputeIdentityProvided) {
      const proceed = window.confirm(
        'You have not verified your identity. This may delay or deny dispute resolution. Continue?'
      );
      if (!proceed) return;
    }
  
    const formData = new FormData();
    formData.append('walletId', walletId);
    formData.append('disputeLevel', disputeLevel);
    formData.append('evidence', disputeEvidence);
    formData.append('identityProvided', disputeIdentityProvided);
    if (disputeFiles) {
      Array.from(disputeFiles).forEach((file) => {
        formData.append('files', file);
      });
    }
  
    if (disputeLevel === 'employees') {
      // Save form and trigger payment
      setPendingDisputeForm(formData);
      setShowPaymentModal(true);
    } else {
      // Free tier — just submit now
      await onInitiateDispute(formData);
      onHide();
    }
  };

  const handleDisputePaymentSuccess = async () => {
    if (!pendingDisputeForm) return;
  
    try {
      await onInitiateDispute(pendingDisputeForm);
      setPendingDisputeForm(null);
      setShowPaymentModal(false);
      onHide();
    } catch (err) {
      console.error('Failed to submit dispute after payment:', err);
      alert('Dispute creation failed after payment.');
    }
  };

  const handleConfirmDispute = async () => {
    try {
      await handleSubmit();
      setShowSuccessModal(false);
    } catch (err) {
      console.error('Dispute confirmation failed:', err);
    }
  };
  
  
  

  return (
    <>
      <Modal show={show} onHide={onHide} container={document.body}>
        <Modal.Header closeButton>
          <Modal.Title>Initiate Dispute</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="disputeLevel">
              <Form.Label>Dispute Level</Form.Label>
              <Form.Control
                as="select"
                value={disputeLevel}
                onChange={(e) => setDisputeLevel(e.target.value)}
              >
                <option value="random_mediators">Random Mediators (5 users)</option>
                <option value="employees">Webapp Employees ($50 fee)</option>
                <option value="arbitration">Arbitration</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="disputeEvidence" className="mt-3">
              <Form.Label>What went wrong? Provide detailed explanation:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={disputeEvidence}
                onChange={(e) => setDisputeEvidence(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Identity Verification</Form.Label>
              {disputeIdentityProvided ? (
                <p style={{ color: 'lightgreen' }}>Your identity has been verified.</p>
              ) : (
                <button type="button" className="button" onClick={() => setShowKycModal(true)}>
                  Verify Identity
                </button>
              )}
            </Form.Group>
            <Form.Group controlId="disputeFiles" className="mt-3">
              <Form.Label>Upload supporting files</Form.Label>
              <Form.Control
                type="file"
                multiple
                accept="image/jpeg, image/jpg, image/png, image/gif, application/pdf"
                onChange={(e) => setDisputeFiles(e.target.files)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="button" onClick={onHide}>Cancel</button>
          <button className="button" onClick={handleSubmit}>
            Initiate Dispute
          </button>
        </Modal.Footer>
      </Modal>
  
      {/* ✅ Keep only one of these */}
      <StripeDisputePaymentModal
        show={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        walletId={walletId}
        onSuccess={handleDisputePaymentSuccess}
      />
  
      <DisputeSuccessModal
        show={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onConfirm={handleConfirmDispute}
      />
    </>
  );
}